import request from '@/utils/request';

// 检查邮箱
export const checkEmail = (params: { email: string; [key: string]: any }) => {
  return request.get('/userApi/portal/checkEmailIfExist.do', {
    params,
  });
};

// 检查邮箱
export const sendResetPassword = (data: any) => {
  return request.post('/userApi/portal/sendResetPasswordLink.do', {
    data,
  });
};

// 检查登录
export const checkIsLogin = (params: any) => {
  return request.get('/userApi/portal/isLogin.do', {
    params,
  });
};

// 检查是否拥有用户信息
export const checkUserInfo = (params: any) => {
  return request.get('/userApi/portal/checkUserInfo.do', {
    params,
  });
};

// 登录
export const login = (data: { userIdentifier?: string; userPwd?: string; salt?: string; loginMethod?: string }) => {
  return request.post('/userApi/portal/login.do', {
    data,
  });
};

// 登出
export const logout = (params: any) => {
  return request.get('/userApi/portal/logout.do', {
    params,
  });
};

// 重置密码
export const checkUsernameAndPassword = (data: { userPwd: string; userName: string; timestamp?: number }) => {
  return request.post('/userApi/portal/checkUserNameAndPassWord.do', {
    data,
  });
};

// 重置密码
export const resetPassword = (data: { newUserPwd: string; oldUserPwd: string; salt: string; timestamp?: number }) => {
  return request.post('/userApi/user/v2/updateUserPwd.do', {
    data,
  });
};

// 获取用户信息
export const fetchUserInfo = (params: any) => {
  return request.get('/userApi/user/getUserInfoNew.do', {
    params,
  });
};

// 获取账户状态信息
export const fetchAccountInfo = (params?: any) => {
  return request.get('/api/account/account/info.do', {
    params,
  });
};

// 添加用户信息
export const saveUserInfo = (data: any) => {
  return request.post('/userApi/portal/improveUserInfo.do', {
    data,
  });
};
// 校验邮箱是否被注册过
export const checkEmailIfUnique = (params: { email: string; _?: number }) =>
  request.get('/userApi/portal/checkEmailIfUnique.do', { params });
// 发送验证码验证邮箱
export const sendEmailVerifyCode = (data: { email: string; timestamp: number; verifyScene: string }) =>
  request.post('/userApi/common/sendEmailVerifyCode.do', { data });
// 发送验证码验证旧邮箱
export const sendOldEmailVerifyCode = (data: { userId: any; verifyScene: string }) =>
  request.post('/api/user/common/sendVerifyCode.do', { data });
// 注册
export const registerEmail = (data: {
  verifyCode: string;
  email: string;
  userPwd: string;
  inviteCode?: string | null;
  promoteWay?: string | null;
  promoteId?: string | null;
}) => request.post('/userApi/portal/email-register-auth.do', { data });
// 谷歌注册
export const registerGeogle = (data: any) => request.post('/userApi/render/google/register.do', { data });
// 谷歌登录
export const loginGeogle = (data: any) => {
  return request.post('/userApi/render/google/login.do', {
    data,
  });
};
// 谷歌登录回调
export const oauthCallBackGeogle = (params: any) => {
  return request.get('/userApi/oauth/callback/google.do', {
    params,
  });
};
// 谷歌注册回调
export const registerCallBackGeogle = (params: any) => {
  return request.get('/userApi/register/callback/google.do', {
    params,
  });
};

// 校验充值密码link
export const checkResetLink = (data: { token: string; _?: number }) =>
  request.post('/userApi/portal/checkLink.do', { data });

// 重置密码
export const resetPasswordByLink = (data: { token: string; newUserPwd: string; _?: number }) =>
  request.post('/userApi/portal/resetPasswordByLink.do', { data });

// 检测ip前后两次登录时是否一致
export const checkUserIp = (email: string) => request.get(`/userApi/portal/user/ip.do?email=${email}`);

// github注册
export const registerGithub = (data: any) => request.post('/userApi/render/github/register.do', { data });
// github登录
export const loginGithub = (data: any) => {
  return request.post('/userApi/render/github/login.do', {
    data,
  });
};
// github登录回调
export const oauthCallBackGithub = (params: any) => {
  return request.get('/userApi/oauth/callback/github.do', {
    params,
  });
};
// github注册回调
export const registerCallBackGithub = (params: any) => {
  return request.get('/userApi/register/callback/github.do', {
    params,
  });
};

// 双因子登录
type twoFaType = {
  state: string; //登录页返回的state
  code: number; //用户填写的双因子验证码
};
export const postTwoFALogin_api = (data: twoFaType) => {
  return request.post('/userApi/portal/twoFALogin.do', {
    data,
  });
};

// 创建双因子认证
export const create2fa = (data: { remark: string }) => {
  return request.post('/userApi/create2fa.do', { data });
};
// 确认开启双因子认证
export const confirm2fa = (data: { uuid: string; code: string }) => {
  return request.post('/userApi/confirm2fa.do', { data });
};
// 获取双因子认证列表
export const fetch2faList = (params: any) => {
  return request.get('/userApi/list2fa.do', { params });
};
// 删除双因子认证
export const remove2fa = (uuid: string) => {
  return request.delete(`/userApi/remove2fa/${uuid}.do`);
};
// 重置双因子认证
export const reset2fa = (data: { token: string }) => {
  return request.post('/userApi/reset2fa.do', { data });
};
// 检查第三方授权是否存在密码
export const isEmptyPassword = (params: { username: string }) => {
  return request.get('/userApi/users/password/is-empty', { params });
};
// 第三方授权设置密码
export const setPassword = (data: { password: string }) => {
  return request.put(`/userApi/users/password?password=${data.password}`, { data });
};
// 修改邮箱-验证旧邮箱
export const resetUserOldEmailVerify = (data: { email: string; verifyCode: number }) => {
  return request.post('/api/user/v1/user/resetUserOldEmailVerify.do', { data });
};
// 修改接收邮箱
export const resetUserReceiveEmail = (data: { email: string; verifyCode: string }) => {
  return request.post('/api/user/v1/user/resetUserReceiveEmail.do', { data });
};
// 查询当前用户的余额通知配置
export const getBalanceNotifySelf = () => {
  return request.get('/api/account/accounts/balanceNotify/self.do');
};
// 修改当前用户的余额通知配置
export const postBalanceNotifySelf = (data: {
  id: string;
  balanceNotifyMinAmount: number;
  balanceNotifyEnable: boolean;
}) => {
  return request.post('/api/account/accounts/balanceNotify/self.do', { data });
};
// 修改邮箱-验证新邮箱
export const resetUserNewEmailVerify = (data: { email: string; verifyCode: number }) => {
  return request.post('/api/user/v1/user/resetUserNewEmailVerify.do', { data });
};

import account from './account/ja-JP.json';
import affiliate from './affiliate/ja-JP.json';
import component from './component/ja-JP.json';
import menus from './menus/ja-JP.json';
import user from './users/ja-JP.json';
import global from './global/ja-JP.json';
import support from './support/ja-JP.json';
import result from './result/ja-JP.json';
import finance from './finance/ja-JP.json';
import region from './region/ja-JP.json';
import request from './request/ja-JP.json';
// 因不知名原因，当存在menu和user文件夹时，i18n-ally插件没反应，故修改文件名为menus,users

const titleMenu = Object.entries(menus).reduce((pre: object, [key, value]: [string, string]) => {
  // 添加统一后缀
  return {
    ...pre,
    [`title.${key}`]: `${value} - LightNode`,
  };
}, {});

export default {
  ...account,
  ...affiliate,
  ...component,
  ...menus,
  ...titleMenu,
  ...user,
  ...global,
  ...support,
  ...result,
  ...finance,
  ...region,
  ...request,
};
